
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  min-width:320px;
  overflow: hidden;
}

html, body, h1, h2, h3, h4, h5, h6, div, p, blockquote, pre, address, ul, ol, li, dl, dt, dd, caption, form, fieldset, legend, input {
  margin: 0;
  padding: 0;
  letter-spacing: 0px;
}

button{
  background: inherit ; border:none; box-shadow:none; border-radius:0; padding:0; overflow:visible; cursor:pointer;
}

body {
  -webkit-overflow-scrolling: touch !important;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Noto Sans KR", sans-serif;
  /* background: #f2f2f2; */
  background: linear-gradient(#f2f2f2, 70%, #c9c9c9);
  /* cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxMCIgZmlsbD0iI0U4QjA1OSIvPjwvc3ZnPg=='),auto; */
}

a {
  color: black;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

/* scroll */
::-webkit-scrollbar {
  width: 3px;
  height: 6px;
}


::-webkit-scrollbar-track {
  background-color: hsla(0, 0%, 0%, 0.2);
  border-radius: 0px
}

::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #000;
}
/* scroll end */

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 1.5s forwards;
}



.footerWrap{
  position: absolute;
  width:100%;
bottom: 21px;
  left:9px;
  z-index: 10;
}
.footerWrap >img {
  position: absolute;
  width:42px;
  height:auto;
  top:0;
}

.footerWrap > h5{
  font-size: 13px;
  font-weight: bold;
  color:#000;
  padding-left: 45px;
}

.footerWrap > h6{
  font-size: 11px;
  color:#303030;
  padding-left: 45px;
}

.footerWrap > a{
  position: absolute;
  right:27px;
  top:12px;
}

.footerWrap  .email{
  fill:#202020;
}

.footerWrap  .email:hover{
  fill:#fff;
  transition: .2s;
}
.modal{
  position: fixed;
  width:100%;
  height:100vh;
  z-index: 20;
  background-color: #f3f3f3;
}
.closeBtn{
  cursor: pointer;
  position: absolute;
  right:9px;
  top:9px;
  z-index: 10;
  background-color: transparent;
}
.closeIcon{
  fill:#000;
  width:27px;
  height:27px;
  transition: .2s;

}

.closeIcon:hover{
  fill:#fff;
}


.modalLogo{
  width:45px;
  height:auto;
  margin: 9px;
}
.line{
  position: absolute;
  width:1px;
  background-color: #cfcfcf;
  top:40px;
  left:31px;
  z-index: -1;
}

.modalWrap{
  display: flex;
  flex-direction: column;
  align-items:center;
  width:100%;
  height:100vh;
  overflow-y: auto;
}
.contentsWrap{
  position: relative;
  display: flex;
  flex-direction: column;
  width:100%;
  max-width: 1200px;
}

@media only screen and (max-width: 1900px) {
  .contentsWrap{
    max-width: 1000px;
  }
}



.Portfolio{
  position: relative;
  margin: 15px 0 60px;
  padding-top: 15px;
}

.titleWrap{
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 0 6px 30px;
}
.dot{
  position: absolute;
  width:12px;
  height:12px;
  border-radius: 50%;
  background-color: #000;
  top:5px;
  left:25px;
}
.date{
  font-size:13px;
  font-weight: 400;
  padding-left: 15px;
}

.title{
  margin-top: 9px;
  padding-left: 15px;
  font-weight: 900;
  font-size: 21px;
}



.etc {
  display: flex;
  align-items: center;
  padding-left: 45px;
  margin: 2px 0;
}

.etc > p{
  font-size: 12px;
  color:#afafaf;
  width:60px;
  font-weight: 500;
}
.etc > h6{
  font-size: 12px;
  color:#363636;
  font-weight: 700;
}

.etc > a{
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  color:#363636;
}

.etc > a:hover{
  color:#000;
  text-decoration: underline;
}

.dec{
  margin: 6px 0;
  padding-left: 45px;
  font-size: 15px;
  font-weight: 600;
  width:calc(100% - 36px);
}


@media only screen and (max-width: 1024px) {
  .title{
    font-size: 17px;
  }

  .dec{
    font-size: 13px;
  }

}


.poImgWrap{
  padding-left: 45px;
  margin: 18px 0;
}
.poImg{
  width:calc(100% - 36px);
  height:auto;
}
.videoWrap{
  padding-left: 45px;
  margin: 18px 0;
}

.videoWrap > video{
  width:calc(100% - 36px);
  height:auto;
}

.scrollGuide{
  position: absolute;display: flex;
  flex-direction: column;
  align-items: center;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  background-color: #fff;
  border-radius: 50%;
  padding: 30px;
}
.scrollGuide> p {
  font-size:13px;
  font-weight: 700;
  padding-top: 6px;
}


@keyframes scroll {
	0% {
		transform: translateY(0);
	}
	30% {
		transform: translateY(60px);
	}
}

svg #wheel {
	animation: scroll ease 2s infinite;
}

*,
*::before,
*::after {
	box-sizing: border-box;
	-webkit-backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;
}


.GuideWrap{
  position: absolute;
  width:190px;
  height:190px;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
  animation-name: hide;
  animation-duration: 3.2s;
  animation-fill-mode: forwards;
}

@keyframes hide {
  0% { opacity: 1; }
  60% { opacity: 1; }
  99% { opacity: 0; }
  100% { display: none;}
}

.scroll-downs {
  position: absolute;
  left:50%;
  top:45%;
  transform: translate(-50%,-50%);
  margin: auto;
  width :39px;
  height: 55px;
}
.mousey {
  width: 3px;
  padding: 9px 15px;
  height: 30px;
  border: 3px solid #000;
  border-radius: 18px;
  opacity: 1;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 9px;
  border-radius: 4px;
  background-color: #000;
  animation-name: scroll;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
.GuideWrap > p {
  color:#000;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  position: absolute;
  top:70%;
  left:50%;
  transform: translate(-50%,-50%);
  white-space : nowrap;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(12px); opacity: 0;}
}